import './Coupon.scss';
import unitCurve from '../../assets/images/unit-curve-black.svg';
import FavoriteButton from '../FavoriteButton/FavoriteButton';
import { useDispatch, useSelector } from 'react-redux';
import { mainSelector } from '../../_selectors/main.selector';
import { authSelector } from '../../_selectors/auth.selector';
import { favoriteCouponsSelector } from '../../_selectors/coupon.selector';
import { addToFavourite, removeFromFavourite, toggleModalShow } from '../../_actions';
import defaultCouponImage from '../../assets/images/no-coupon.svg';
import cn from 'classnames/bind';
import CouponExpiration from './CouponExpiration';
import { useHistory } from 'react-router-dom';
import AgeModal from '../../_modals/AgeModal';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../_hook/useTranslation';
import QuickReview from '../QuickReview/QuickReview';
import { QUICK_REVIEW_MODAL } from '../../_constants';
import { isRTL } from '../../_helpers/functions';
import CouponDistance from './CouponDistance';

function Coupon({ coupon, inSlider, special = false, className = '', style = {} }) {
  const { locale } = useSelector(mainSelector);
  const { user, isLoggedIn } = useSelector(authSelector);
  const favoriteCoupons = useSelector(favoriteCouponsSelector);
  const t = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const couponUrl = encodeURIComponent(coupon?.url?.split('/')?.join('-'));
  const providerName = encodeURIComponent(coupon?.company?.name?.replace(/\s/g, '-').split('/').join('-'));

  const makeProviderUrl = () => {
    return `/${locale}/company/${providerName}-p/${coupon.company?._id}`;
  };

  const isImageExist = coupon?.imgPath && coupon?.imgPath[0];
  const imgPath = isImageExist ? process.env.REACT_APP_URL_IMG + coupon.imgPath[0] : defaultCouponImage;

  const couponName = coupon?.name?.length > 50 ? coupon?.name?.slice(0, 50) + '...' : coupon?.name;
  const isPercentage = coupon?.coupon_type === 'Percentage';

  const handleAddToFavorite = (e, coupon) => {
    e.preventDefault();
    dispatch(addToFavourite(coupon, locale));
  };

  const handleRemoveFromFavourite = (e, id) => {
    e.preventDefault();
    dispatch(removeFromFavourite(id, locale));
  };

  const navigateToDetails = () => {
    const action = () => {
      history.push({
        pathname: `/${locale}/coupon/${providerName}-p/${couponUrl}-coupon/${coupon._id}`,
      });
    };

    if (coupon.alcoholAndTobaco?.[0]?.value !== 0) {
      AgeModal.confirm({
        coupon,
        locale,
        onOk: action,
      });
    } else {
      action();
    }
  };

  const handleOpenModal = () => {
    dispatch(toggleModalShow(QUICK_REVIEW_MODAL, { coupon }));
  };

  return (
    <div
      style={style}
      onClick={navigateToDetails}
      className={cn('coupon', special ? 'coupon--special' : '', inSlider ? 'couponInSlider' : '')}
    >
      <CouponExpiration isSpecial={special} expiresAt={coupon.expired_at} />
      <div className="coupon__image">
        <div className="coupon__favourite">
          <FavoriteButton
            rtl={locale === 'he'}
            inSlider={false}
            coupon={coupon}
            isLogged={isLoggedIn}
            user={user}
            language={locale}
            favouriteCoupons={favoriteCoupons}
            handleAddToFavorite={handleAddToFavorite}
            handleRemoveFromFavourite={handleRemoveFromFavourite}
          />
          <QuickReview coupon={coupon} language={locale} toggleQuickView={handleOpenModal} />
        </div>

        <img src={imgPath} className={cn(!isImageExist ? 'image-placeholder' : '')} alt="coupon" />
      </div>
      <div className="coupon__content text-right">
        {!special && (
          <div className="coupon__price">
            <div className="coupon__price-data">
              {!isPercentage ? (
                <div className={cn('value', { 'big-value': coupon.price > 99 })}>{coupon.price}</div>
              ) : (
                <div className={cn('value')}>{coupon.percentage + '%'}</div>
              )}
              {!isPercentage && (
                <div className="unit">
                  <span>₪</span>
                </div>
              )}

              {/* <img src={unitCurve} alt="" /> */}
            </div>
            <div className="coupon__discount">{t('discount')}</div>
          </div>
        )}
        <div
          style={{ direction: isRTL(coupon.name) ? 'rtl' : 'inherit' }}
          className={cn('coupon__name', { 'text-right-force': isRTL(coupon.name) })}
        >
          {coupon.name}
        </div>
        <div className="coupon__data-wrapper">
          <div className="coupon__data">
            {special && (
              <p className="coupon__special-price">
                {locale === 'he' && <span className="special-discount">{t('discount')}</span>}
                {!isPercentage && <span className="unit">₪</span>}{' '}
                {isPercentage ? coupon.percentage + '%' : coupon.price}
                {locale !== 'he' && <span className="special-discount">{t('discount')}</span>}
              </p>
            )}
            <p className={cn('coupon__realizations', { 'ru-realizations': locale === 'ru' })}>
              {coupon.redemptions > 0 ? coupon.redemptions + ' ' + t('realized_card') : ''}
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
              }}
            >
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push(makeProviderUrl());
                }}
                to={makeProviderUrl()}
                className="coupon__company"
              >
                {coupon.company?.name}
              </Link>
              {coupon?.distance || coupon?.online ? (
                <CouponDistance online={coupon.online} distance={coupon.distance} locale={locale} />
              ) : null}
            </div>
          </div>
          {special && (
            <button style={{ fontSize: '14px' }} className="btn btn-primary btn-lg wid-100">
              {t('chipper_me')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Coupon;
